.container {
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  padding-top: 0;
  background-color: none;
  padding-bottom: 1.2rem;

  @media (max-width: 768px) {
    border-top: none;
    border-bottom: none;
    padding: 0;
  }
}
.errorContainer {
  @media (max-width: 768px) {
    background-color: var(--white);
    padding: 1.2rem 0;
  }
}

.warning {
  color: var(--mantine-primary-color-filled);
  fill: var(--mantine-primary-color-filled);
}

.whoscoming {
  @media (max-width: 768px) {
    background-color: var(--white);
    padding: 1.2rem 1.2rem;
  }
}
