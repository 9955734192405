.container {
  max-width: 100%;
  height: 100%;
  margin-top: -2px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 1.4rem;
  }
}

.containerL2 {
  width: 100%;
  background-color: var(--white);
}

.logoWrapper {
  width: 100%;
  background-color: var(--header-nav-bg);
  box-shadow: 4px 4px 4px 4px var(--shadow);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100px;
  @media (max-width: 768px) {
    height: 50px;
  }
  z-index: 200;
}

.contentWrapper {
  padding-bottom: 6rem;

  @media (max-width: 768px) {
    padding: 0 0 6rem 0;
  }
}

.bottomNavigationWrapper {
  width: 100%;
  background-color: var(--white);
  position: fixed;
  z-index: 200;
  box-shadow: 4px -4px 4px 0px var(--shadow);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 2rem;
  justify-content: center;
}

.bottomNavigationContainer {
  width: 100%;
  max-width: 591px;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}
