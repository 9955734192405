.container {
  width: width;
  margin: 0 auto;
  border-radius: 0.4rem;
  @media (max-width: 1407px) {
    width: 100%;
  }
}
.image {
  @media (max-width: 1407px) {
    width: 100%;
    height: 100%;
  }
}
.centerMobile {
  @media (max-width: 1407px) {
    text-align: center;
  }
}
.containerPrice {
  justify-content: initial;
  @media (max-width: 1407px) {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    justify-content: center;
  }
}
.textPrice {
  font-size: rem(28px);
}
