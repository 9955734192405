.anchor {
  width: fit-content;
  text-decoration: none;
  background: transparent;
  &:hover {
    background-color: transparent !important;
    text-decoration: none;
  }
  & button {
    background-color: transparent !important;
  }

  svg {
    fill: var(--brand-color);
  }

  svg path {
    stroke: var(--brand-color);
  }
}

.navLink {
  color: var(--theme);
}
