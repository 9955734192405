.messageStyle {
  padding-top: 0.5rem;
  @media (max-width: 768px) {
    background-color: var(--white);
    padding: 1.2rem 1.2rem;
  }
}

.showBorderTop {
  border-top: 1px solid var(--borderColor);
  @media (max-width: 768px) {
    border-top: none;
  }
}

.showBorderBottom {
  border-bottom: 1px solid var(--borderColor);
  @media (max-width: 768px) {
    border-bottom: none;
  }
}

.text {
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.1rem;
}
