.container {
  @media (max-width: 768px) {
    background-color: var(--white);
    padding: 1.2rem 1.2rem;
  }
}
.containerFlex {
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.day {
  &[data-weekend] {
    color: var(--blue);
  }
}
