.container {
  justify-content: center;

  @media (max-width: 768px) {
    background-color: var(--background);
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: initial;
  }
}

.stack {
  max-width: 591px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.titleWrapper {
  padding: 0px;
  margin: 0;

  @media (min-width: 768px) {
    display: none;
  }
}
.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
