.BookingAmendmentPaymentInfoMessage_messageStyle__aIR3Z {
  padding-top: 0.5rem;
}
  @media (max-width: 768px) {.BookingAmendmentPaymentInfoMessage_messageStyle__aIR3Z {
    background-color: var(--white);
    padding: 1.2rem 1.2rem
}
  }

.BookingAmendmentPaymentInfoMessage_showBorderTop__BIsbz {
  border-top: 1px solid var(--borderColor);
}

@media (max-width: 768px) {

.BookingAmendmentPaymentInfoMessage_showBorderTop__BIsbz {
    border-top: none
}
  }

.BookingAmendmentPaymentInfoMessage_showBorderBottom__aod5f {
  border-bottom: 1px solid var(--borderColor);
}

@media (max-width: 768px) {

.BookingAmendmentPaymentInfoMessage_showBorderBottom__aod5f {
    border-bottom: none
}
  }

.BookingAmendmentPaymentInfoMessage_text__Y0iUz {
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.1rem;
}

.BlockWrapper_sectionBackground__LBO2D {
  background-color: var(--background);
}

  @media (max-width: 768px) {.BlockWrapper_sectionBackground__LBO2D {
    background-color: #fff;
    border-bottom: 0;
    border-bottom: initial
}
  }

@media (min-width: 768px) {.layout_border__CcOZG {
    border-bottom: 1px solid var(--borderSection)
}
  }
  @media (max-width: 768px) {.layout_border__CcOZG {
    border-bottom: 0;
    border-bottom: initial
}
  }

.QuantityInput_icon__wqZyX {
  font-size: 24px;
}
.QuantityInput_iconLeft__OGaLO {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.QuantityInput_iconRight__WJmOj {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.QuantityInput_iconDisabled__3pvCX {
  border-color: #ced4da;
  background-color: #fff;
}
.QuantityInput_numberInput__Zs1hI {
  font-size: 18px;
}
.QuantityInput_numberInput__Zs1hI:focus {
    border-color: #ced4da;
  }
.QuantityInput_numberInput__Zs1hI {
  text-align: center;
  border-left: none;
  border-right: none;
  height: 50px;
  border-radius: 0;
}
.QuantityInput_numberInputRoot__kIOMr {
  border-left: none;
  border-right: none;
}
.QuantityInput_navLink__tPa1T {
  color: var(--theme);
}

.SvgAdapter_svgContainer__Z6YO1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

  .SvgAdapter_svgContainer__Z6YO1 svg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SvgAdapter_svgContainer__Z6YO1 svg path {
      fill: var(--svg-fill);
    }

@media (max-width: 768px) {.FlightInfo_container__TBz_D {
    background-color: var(--white);
    padding: 1.2rem 1.2rem
}
  }
.FlightInfo_containerFlex__mZESh {
  flex-direction: row;
}
@media (max-width: 768px) {
.FlightInfo_containerFlex__mZESh {
    flex-direction: column
}
  }
.FlightInfo_day__dAPxQ[data-weekend] {
    color: var(--blue);
  }

.Notification_container__j0_nd {
  background-color: rgba(212, 42, 84, 0.1);
  border: 2px solid #d42a54;
  border-radius: 8;
}
  @media (max-width: 768px) {.Notification_container__j0_nd {
    margin-left: 1rem;
    margin-right: 1rem
}
  }
.Notification_fullHeight___DT8w {
  height: 100%;
}
@media (max-width: 768px) {
.Notification_iconContainer__OPfX2 {
    paddingtop: 0.2rem
}
  }
.Notification_text__BigJi {
  text-align: left;
  word-wrap: break-word;
  fontsize: 0.875rem;
  margintop: 0.3rem;
}

.Guestinfo_container__d_iaq {
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  padding-top: 0;
  background-color: none;
  padding-bottom: 1.2rem;
}

  @media (max-width: 768px) {.Guestinfo_container__d_iaq {
    border-top: none;
    border-bottom: none;
    padding: 0
}
  }
@media (max-width: 768px) {
.Guestinfo_errorContainer__1jnFs {
    background-color: var(--white);
    padding: 1.2rem 0
}
  }

.Guestinfo_warning__npWfi {
  color: var(--mantine-primary-color-filled);
  fill: var(--mantine-primary-color-filled);
}

@media (max-width: 768px) {

.Guestinfo_whoscoming____uxB {
    background-color: var(--white);
    padding: 1.2rem 1.2rem
}
  }

.Layout_container__XHkJY {
  max-width: 100%;
  height: 100%;
  margin-top: -2px;
}

  @media (max-width: 768px) {.Layout_container__XHkJY {
    margin: 0;
    padding: 0
}
  }

  .Layout_container__XHkJY h1 {
    font-size: 1.4rem;
  }

.Layout_containerL2__Ihuc9 {
  width: 100%;
  background-color: var(--white);
}

.Layout_logoWrapper__vkaJB {
  width: 100%;
  background-color: var(--header-nav-bg);
  box-shadow: 4px 4px 4px 4px var(--shadow);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100px;
}

@media (max-width: 768px) {

.Layout_logoWrapper__vkaJB {
    height: 50px;
}
  }

.Layout_logoWrapper__vkaJB {
  z-index: 200;
}

.Layout_contentWrapper__o7P7a {
  padding-bottom: 6rem;
}

@media (max-width: 768px) {

.Layout_contentWrapper__o7P7a {
    padding: 0 0 6rem 0
}
  }

.Layout_bottomNavigationWrapper__iquma {
  width: 100%;
  background-color: var(--white);
  position: fixed;
  z-index: 200;
  box-shadow: 4px -4px 4px 0px var(--shadow);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 2rem;
  justify-content: center;
}

.Layout_bottomNavigationContainer__RCqw5 {
  width: 100%;
  max-width: 591px;
  justify-content: center;
}

@media (max-width: 768px) {

.Layout_bottomNavigationContainer__RCqw5 {
    max-width: 100%
}
  }

.LoungeInfo_container__YyU50 {
  width: width;
  margin: 0 auto;
  border-radius: 0.4rem;
}
  @media (max-width: 1407px) {.LoungeInfo_container__YyU50 {
    width: 100%
}
  }
@media (max-width: 1407px) {
.LoungeInfo_image__Bcc9H {
    width: 100%;
    height: 100%
}
  }
@media (max-width: 1407px) {
.LoungeInfo_centerMobile__LtFjB {
    text-align: center
}
  }
.LoungeInfo_containerPrice__vskVd {
  justify-content: initial;
}
@media (max-width: 1407px) {
.LoungeInfo_containerPrice__vskVd {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    justify-content: center
}
  }
.LoungeInfo_textPrice__4sYf8 {
  font-size: calc(1.75rem * var(--mantine-scale));
}

.TopBarLinks_anchor__bZ8dw {
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  background: transparent;
}
  .TopBarLinks_anchor__bZ8dw:hover {
    background-color: transparent !important;
    text-decoration: none;
  }
  .TopBarLinks_anchor__bZ8dw button {
    background-color: transparent !important;
  }
  .TopBarLinks_anchor__bZ8dw svg {
    fill: var(--brand-color);
  }
  .TopBarLinks_anchor__bZ8dw svg path {
    stroke: var(--brand-color);
  }

.TopBarLinks_navLink__c_TkP {
  color: var(--theme);
}

.CheckAvailability_container__sRBxi {
  justify-content: center;
}

  @media (max-width: 768px) {.CheckAvailability_container__sRBxi {
    background-color: var(--background);
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: initial
}
  }

.CheckAvailability_stack__AcXaf {
  max-width: 591px;
}

@media (max-width: 768px) {

.CheckAvailability_stack__AcXaf {
    max-width: 100%
}
  }

.CheckAvailability_titleWrapper__l02Ej {
  padding: 0px;
  margin: 0;
}

@media (min-width: 768px) {

.CheckAvailability_titleWrapper__l02Ej {
    display: none
}
  }
.CheckAvailability_image__k_xDu {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

