.svgContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    path {
      fill: var(--svg-fill);
    }
  }
}
